import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import 'semantic-ui-css/semantic.min.css';

import {
    Segment,
    Button,
    Header,
    Input,
    Loader,
    Dimmer,
    Form,
    Image,
} from 'semantic-ui-react';
import {Box} from 'theme-ui';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './index.css';
import {Login} from './login';
import {PrintTicketModal} from './new-ticket-modal';
import {OrderModal} from './order-modal/order_modal';
import {Footer} from './other/footer';
import JKLogo from './images/JK-logo.png';
import OrderNum from './images/OrderNum.png';
import {Resources} from './order-modal/resources';
const App = () => {
    const [orderId, setOrderId] = useState('');
    const [id, setId] = useState('');
    const [displayLoader, setDisplayLoader] = useState(false);
    const [orderNumber, setOrderNumber] = useState('');
    const [orderNum, setOrderNum] = useState('');
    const [orderNumWithPrint, setOrderNumWithPrint] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [orderedDate, setOrderedDate] = useState('');
    const [order, setOrder] = useState([]);
    const [showLineItems, setShowLineItems] = useState(false);
    const [lineItems, setLineItems] = useState([]);
    const [user, setUser] = useState({});
    const [reprintBy, setReprintBy] = useState('');
    const [displayNotFound, setDisplayNotFound] = useState(false);
    const [credential, setCredential] = useState('');
    const [showFulfilledItems, setShowFulfilledItems] = useState(false)
    var lineItemPosition = 1;

    const handleOrderSearch = async () => {
        if (orderId === '') return;
        setDisplayLoader(true);
        setShowLineItems(false);
        let orderInfoReturn = await fetch(
            'https://11h9xryla4.execute-api.us-east-2.amazonaws.com/default/GetOrderFromDB',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                    'Access-Control-Allow-Methods': '*',
                    'Access-Control-Allow-Origin': '*',
                },
                body: orderId,
            }
        );
        let orderJson = await orderInfoReturn.json();
        let order = orderJson.order;
        console.log(order)


        if (order.items === null) {
            setDisplayLoader(false);
            setDisplayNotFound(true);
        } else {
            setDisplayNotFound(false);
            setOrder(order);
            setShowLineItems(true);
        }
    };

    const handleOrderLoad = async () => {
        let handleOrderLoadResponse = await fetch(
            'https://hc324axerk.execute-api.us-east-2.amazonaws.com/prod/ReprintManualLoad',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(orderNum),
            }
        );

        if (handleOrderLoadResponse.status < 300 && handleOrderLoadResponse.status >= 200) {
            setDisplayLoader(false);
            setDisplayNotFound(true);
            toast.success('Order has been loaded to DB!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        } else {
            setDisplayNotFound(false);
            toast.error('There was an error, please retry!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    };

    const handleOrderLoadAndPrint = async () => {
        let handleOrderLoadResponse = await fetch(
            'https://hc324axerk.execute-api.us-east-2.amazonaws.com/prod/ReprintManualLoad',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify("print " + orderNumWithPrint),
            }
        );

        if (handleOrderLoadResponse.status < 300 && handleOrderLoadResponse.status >= 200) {
            setDisplayLoader(false);
            setDisplayNotFound(true);
            toast.success('Order has been loaded to DB!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        } else {
            setDisplayNotFound(false);
            toast.error('There was an error, please retry!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
    };

    const handleSignOut = () => {
        setUser({});
        setCredential('');
        toast.success('We are signed out!', {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
        });
    };

    useEffect(() => {
        if (order.length === 0) return;
        setLineItems(order.items);
        setId(order.items[0].id)
        setOrderNumber(order.items[0].orderNumberName);
        setOrderedDate(order.items[0].date);
        setCustomerName(
            order.items[0].customerName
        );
        setDisplayLoader(false);
    }, [order]);

    return (
        <div
            style={{
                display: 'flex',
                minHeight: '100vh',
                flexDirection: 'column',
                justifyContent: 'space-between',
                textAlign: 'center',
            }}
        >
            {Object.keys(user).length === 0 ? (
                <Login user={user} setUser={setUser} setCredential={setCredential} setReprintBy={setReprintBy}/>
            ) : (
                <Box>
                    <ToastContainer/>
                    <Image src={JKLogo} size='small' style={{margin: '0 auto'}}/>
                    <Header as='h1' textAlign='center'>
                        JK Boots Ticket Reprint
                    </Header>
                    <Box sx={{mb: 20}}>
                        <Button color='red' size='large' onClick={() => handleSignOut()}>
                            Sign Out
                        </Button>
                    </Box>
                    <Form onSubmit={() => handleOrderSearch()}>
                        <Form.Input
                            placeholder='Enter order number'
                            value={orderId}
                            size='huge'
                            style={{maxWidth: 300}}
                            onChange={(_, {value}) => setOrderId(value)}
                        />
                    </Form>
                    <Segment basic style={{width: 500, margin: '0 auto'}}>
                        {showLineItems ? (
                            <Box>
                                {lineItems.map(function (item) {
                                    if (item.fulfillment_status == null || showFulfilledItems) {
                                        return (
                                            <Box key={item.lineItemId} sx={{margin: '10px'}}>
                                                <OrderModal
                                                    orderNumber={orderNumber}
                                                    lineItem={item}
                                                    lineItemPosition={item.lineItemPosition}
                                                    reprintBy={reprintBy}
                                                    credential={credential}
                                                />
                                            </Box>
                                        );
                                    }
                                })}
                                <PrintTicketModal
                                    customerName={customerName}
                                    orderNumber={orderNumber}
                                    orderId={id}
                                    totalLineItems={lineItems.length}
                                    orderedDate={orderedDate}
                                    reprintBy={reprintBy}
                                    credential={credential}
                                />
                            </Box>
                        ) : (
                            <Box>
                                {displayLoader ? (
                                    <Box sx={{height: '30px'}}>
                                        <Dimmer active inverted>
                                            <Loader/>
                                        </Dimmer>
                                    </Box>
                                ) : null}
                            </Box>
                        )}
                        {displayNotFound ? (
                            <Segment color='orange'>
                                No order found. Make sure order is not archived or enter another
                                one.
                            </Segment>
                        ) : null}
                    </Segment>
                    <br/>
                    <Box sx={{mb: 20}}>
                        <p as='p1' textAlign='center'>
                            If a value shows up as "!NOID" or something similar, please change it by typing in a valid value
                            or selecting one from the drop down, even if it is the same as the one after.
                            Example case: Custom Uppers form will not show unless a valid height is chosen.
                        </p>
                    </Box>
                    <Resources/>
                    <Box sx={{mb: 20}}>
                        <Header as='h2' textAlign='center'>
                            Certain orders need to be manually added to system. Input the number at the end of the URL 
                            of the shopify . Will not print tickets.
                        </Header>
                        <Image src={OrderNum} size='large' style={{margin: '0 auto'}}/>
                    </Box>
                    <Form onSubmit={() => handleOrderLoad()}>
                        <Form.Input
                            placeholder='Enter Order Full ID'
                            value={orderNum}
                            size='huge'
                            style={{maxWidth: 300}}
                            onChange={(_, {value}) => setOrderNum(value)}
                        />
                    </Form>
                    <Box sx={{mb: 20, mt:20}}>
                        <Header as='h2' textAlign='center'>
                            Will send order through system, including printing tickets. Typically for bulk orders.
                        </Header>
                    </Box>
                    <Form onSubmit={() => handleOrderLoadAndPrint()}>
                        <Form.Input
                            placeholder='For Bulk Orders'
                            value={orderNumWithPrint}
                            size='huge'
                            style={{maxWidth: 300}}
                            onChange={(_, {value}) => setOrderNumWithPrint(value)}
                        />
                    </Form>
                </Box>
            )}
            <Footer/>
        </div>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App/>);
