import { React, useEffect, useState } from 'react';
import { Form, Header } from 'semantic-ui-react';

export const NormalPatch = ({ patch, setPatch }) => {
  const [leftMotorcycle, setLeftMotorcycle] = useState(false);
  const [rightMotorcycle, setRightMotorcycle] = useState(false);

  useEffect(() => {
    var text = '';
    if (leftMotorcycle == true) {
      text = text + 'Left Motor. || ';
    }
    if (rightMotorcycle == true) {
      text = text + 'Right Motor. || ';
    }
    text = text.slice(0, -3)
    setPatch(text);
  }, [leftMotorcycle, rightMotorcycle]);

  return (
    <Form.Field>
      <Form.Group grouped>
        <Form.Field
          label='Left Motorcycle Shift Lever Patch'
          control='input'
          type='checkbox'
          onChange={() => setLeftMotorcycle(!leftMotorcycle)}
        />
        <Form.Field
          label='Right Motorcycle Shift Lever Patch'
          control='input'
          type='checkbox'
          onChange={() => setRightMotorcycle(!rightMotorcycle)}
        />
      </Form.Group>
    </Form.Field>
  );
};
