import { React, useState } from 'react';
import { Button, Segment, Input } from 'semantic-ui-react';
import { SideSheathDropdown } from '../boot-model-forms/dropdowns';

export const SideSheath = ({
  setSideSheath,
  setSideSheathText,
  setOpenSideSheathBox,
}) => {
  const [dropdownSideSheathText, setDropdownSideSheathText] = useState('');

  const updateSideSheathText = () => {
    if (dropdownSideSheathText === '') {
      setSideSheathText('');
      setSideSheath('');
      return;
    }
    setSideSheathText('SIDE SHEATH: ' + dropdownSideSheathText);
    setSideSheath('✓');
    setOpenSideSheathBox(false);
  };
  return (
    <Segment>
      <SideSheathDropdown
        setDropdownSideSheathText={setDropdownSideSheathText}
      />
      <Segment basic>
        <Button
          type='button'
          color='green'
          onClick={() => updateSideSheathText()}
        >
          Update
        </Button>
        <Button
          type='button'
          color='red'
          onClick={() => setOpenSideSheathBox(false)}
        >
          Close
        </Button>
      </Segment>
    </Segment>
  );
};
