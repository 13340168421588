import { React, useEffect, useState } from 'react';
import { Form, Header } from 'semantic-ui-react';

export const LinemanPatch = ({ linemanPatch, setLinemanPatch }) => {
  const [ll, setLL] = useState(false);
  const [lr, setLR] = useState(false);
  const [rl, setRL] = useState(false);
  const [rr, setRR] = useState(false);

  useEffect(() => {
    var text = '';
    if (ll == true) {
      text = text + '(Left, Left) ';
    }
    if (lr == true) {
      text = text + '(Left, Right) ';
    }
    if (rl == true) {
      text = text + '(Right, Left) ';
    }
    if (rr == true) {
      text = text + '(Right, Right) ';
    }
    setLinemanPatch(text);
  }, [ll, lr, rl, rr]);

  return (
    <Form.Field>
      <Form.Group grouped>
        <Form.Field
          label='Left leg, left side'
          control='input'
          type='checkbox'
          onChange={() => setLL(!ll)}
        />
        <Form.Field
          label='Left leg, right side'
          control='input'
          type='checkbox'
          onChange={() => setLR(!lr)}
        />
        <Form.Field
          label='Right leg, left side'
          control='input'
          type='checkbox'
          onChange={() => setRL(!rl)}
        />
        <Form.Field
          label='Right leg, right side'
          control='input'
          type='checkbox'
          onChange={() => setRR(!rr)}
        />
      </Form.Group>
    </Form.Field>
  );
};
