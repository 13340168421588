import { React } from 'react';
import { Button } from 'semantic-ui-react';

export const OpenerButton = ({text, setOpener}) => {
  return (
    <Button
      type='button'
      color='orange'
      style={{ marginBottom: 15 }}
      onClick={() => setOpener(true)}
    >
      {text}
    </Button>
  );
};
