import React, {useEffect, useState} from 'react';
import {Modal, Segment, Button, Dimmer, Loader} from 'semantic-ui-react';
import {Box} from 'theme-ui';
import {Ticket} from '../boot-model-forms/ticket';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { type } from '@testing-library/user-event/dist/type';

export const OrderModal = ({
                               lineItem,
                               orderNumber,
                               lineItemPosition,
                               reprintBy,
                               credential,
                           }) => {
    const [open, setOpen] = useState(false);
    const [specFormOpen, setSpecFormOpen] = useState(false);
    const [customReprintButton, setCustomReprintButton] = useState(false);/////////dimmer
    const [infoForForm, setInfoForForm] = useState();
    const [rebuildForm, setRebuildOpen] = useState(false);
    const [showInputs, setShowInputs] = useState(true);
    const [orderType, setOrderType] = useState('none');

    //reprint custom
    const sendToPrinter = async (lineItem, type) => {
        setCustomReprintButton(true);
        setSpecFormOpen(false);
        const string = '';

        lineItem.type = type
        lineItem.printer = type
        lineItem.reprint = true
        lineItem.reprintBy = reprintBy
        lineItem.rackNumber = 0
        console.log("Update ", lineItem)

        let handleReprintResponse = await fetch(
            'https://mwragnf083.execute-api.us-east-2.amazonaws.com/prod/HandleReprint',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': credential,
                },
                body: JSON.stringify(lineItem),
            }
        );

        console.log("order_modal")
        console.log(handleReprintResponse)
        if (handleReprintResponse.status < 300 && handleReprintResponse.status >= 200) {
            setSpecFormOpen(false);
            setShowInputs(true);
            toast.success('Sent to the ' + lineItem.printer + ' Printer!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        } else {
            setSpecFormOpen(false);
            setShowInputs(true);
            toast.error('There was an error, please retry!', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
        }
        setSpecFormOpen(false);
    };
    
    //reprint custom with changes
    const getTicketSpecs = async (lineItem, type) => {
        
        setCustomReprintButton(true);

        setCustomReprintButton(false);
        setInfoForForm(lineItem);
        setOrderType(type);
        setSpecFormOpen(true);
    };


    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            trigger={
                <Box>
                    <Button style={{width: 375}}>{lineItem.name} {lineItem.color} {lineItem.size} {lineItem.productQtyCount}</Button>
                </Box>
            }
        >
            <Modal.Header>{`${lineItem.name} ${lineItem.color} ${lineItem.size} ${lineItem.productQtyCount}`}</Modal.Header>
            <Modal.Content>
                <Segment basic textAlign='center'>
                    <Segment basic> 
                        {lineItem.product_id === 4158696226914 || // rebuild
                        lineItem.product_id === 4158314446946 || //new soles
                        lineItem.product_id === 7668210467014 || // new heels
                        lineItem.product_id === 7724278382790 ? (// general repairs
                            <Box>
                                <Button
                                    onClick={() => sendToPrinter(lineItem, "Rebuild")}
                                    color='teal'
                                >
                                    Print {lineItem.name} Ticket
                                </Button>
                                <Button
                                    onClick={() => getTicketSpecs(lineItem, "Rebuild")}
                                    color='red'
                                >
                                    Reprint with changes
                                </Button>
                                <Button
                                    onClick={() => getTicketSpecs(lineItem, "Warranty")}
                                    color='purple'
                                >
                                    Warranty Ticket
                                </Button>
                                <Button
                                    onClick={() => sendToPrinter(lineItem, "Temporary")}
                                    color='orange'
                                >
                                    Temporary Ticket
                                </Button>
                                <Button
                                    onClick={() => getTicketSpecs(lineItem, "LostTicket")}
                                    color='yellow'
                                >
                                    Lost Ticket
                                </Button>
                            </Box>
                            
                        )// all of the custom boots
                         : 
                        lineItem.product_id === 7741656432838 || //custom arctic 2.0
                        lineItem.product_id === 7741656531142 || //custom caulk logger 2.0
                        lineItem.product_id === 7741656596678 || //custom climber 2.0
                        lineItem.product_id === 7741656629446 || //custom fire inlander 2.0
                        lineItem.product_id === 7741656727750 || //custom forefront 2.0 //not in dynamo db!!!!!!!!!!!!
                        lineItem.product_id === 7741656760518 || //custom harvester 2.0
                        lineItem.product_id === 7741656858822 || //custom ironhide 2.0
                        lineItem.product_id === 7741656924358 || //custom o.t. 2.0
                        lineItem.product_id === 7741657022662 || //custom station boot 2.0
                        lineItem.product_id === 7741657088198 || //custom superduty 2.0
                        lineItem.product_id === 7741657186502    //custom western packer 2.0
                        ?(
                            <Box>
                                <Button
                                    onClick={() => sendToPrinter(lineItem, "CrazyCustom")}
                                    color='teal'
                                >
                                    Reprint Ticket
                                </Button>
                                <Button
                                    color='red'
                                    onClick={() => getTicketSpecs(lineItem, "CrazyCustom")}
                                >
                                    Reprint with changes
                                </Button>
                                <Button
                                    onClick={() => getTicketSpecs(lineItem, "TryOn")}
                                    color='green'
                                >
                                    Try On Ticket
                                </Button>
                                <Button
                                    onClick={() => getTicketSpecs(lineItem, "Warranty")}
                                    color='purple'
                                >
                                    Warranty Ticket
                                </Button>
                                <Button
                                    onClick={() => sendToPrinter(lineItem, "Temporary")}
                                    color='orange'
                                >
                                    Temporary Ticket
                                </Button>
                                <Button
                                    onClick={() => getTicketSpecs(lineItem, "LostTicket")}
                                    color='yellow'
                                >
                                    Lost Ticket
                                </Button>
                            </Box>


                        ) // all of the custom boots
                        : lineItem.product_id === 7610903298246 || //custom arctic
                       lineItem.product_id === 7741656432838 || //custom arctic 2.0
                       lineItem.product_id === 7648052478150 || //custom caulk logger
                       lineItem.product_id === 7741656531142 || //custom caulk logger 2.0
                       lineItem.product_id === 7610957234374 || //custom climber
                       lineItem.product_id === 7741656596678 || //custom climber 2.0
                       lineItem.product_id === 7610901496006 || //custom fire inlander
                       lineItem.product_id === 7741656629446 || //custom fire inlander 2.0
                       lineItem.product_id === 7610897072326 || //custom forefront
                       lineItem.product_id === 7741656727750 || //custom forefront 2.0
                       lineItem.product_id === 7610946257094 || //custom harvester
                       lineItem.product_id === 7741656760518 || //custom harvester 2.0
                       lineItem.product_id === 7610950811846 || //custom ironhide
                       lineItem.product_id === 7741656858822 || //custom ironhide 2.0
                       lineItem.product_id === 7610894483654 || //custom o.t.
                       lineItem.product_id === 7741656924358 || //custom o.t. 2.0
                       lineItem.product_id === 7157539963078 || //custom station boot
                       lineItem.product_id === 7741657022662 || //custom station boot 2.0
                       lineItem.product_id === 7610383990982 || //custom superduty
                       lineItem.product_id === 7741657088198 || //custom superduty 2.0
                       lineItem.product_id === 7610908147910 || //custom western packer
                       lineItem.product_id === 7741657186502    //custom western packer 2.0
                       ?(
                           <Box>
                                <Button
                                    onClick={() => sendToPrinter(lineItem, "Custom")}
                                    color='teal'
                                >
                                    Reprint Ticket
                                </Button>
                                <Button
                                    color='red'
                                    onClick={() => getTicketSpecs(lineItem, "Custom")}
                                >
                                    Reprint with changes
                                </Button>
                                <Button
                                    onClick={() => getTicketSpecs(lineItem, "TryOn")}
                                    color='green'
                                >
                                    Try On Ticket
                                </Button>
                                <Button
                                    onClick={() => getTicketSpecs(lineItem, "Warranty")}
                                    color='purple'
                                >
                                    Warranty Ticket
                                </Button>
                                <Button
                                    onClick={() => sendToPrinter(lineItem, "Temporary")}
                                    color='orange'
                                >
                                    Temporary Ticket
                                </Button>
                                <Button
                                    onClick={() => getTicketSpecs(lineItem, "LostTicket")}
                                    color='yellow'
                                >
                                    Lost Ticket
                                </Button>
                           </Box>


                       ): (
                            <Box>
                                <Button
                                    onClick={() => sendToPrinter(lineItem, lineItem.type)}
                                    color='teal'
                                >
                                    Reprint Ticket
                                </Button>
                                <Button
                                    color='red'
                                    onClick={() => getTicketSpecs(lineItem, lineItem.type)}
                                >
                                    Reprint with changes
                                </Button>
                                <Button
                                    onClick={() => getTicketSpecs(lineItem, "Warranty")}
                                    color='purple'
                                >
                                    Warranty Ticket
                                </Button>
                                <Button
                                    onClick={() => sendToPrinter(lineItem, "Temporary")}
                                    color='orange'
                                >
                                    Temporary Ticket
                                </Button>
                                <Button
                                    onClick={() => getTicketSpecs(lineItem, "LostTicket")}
                                    color='yellow'
                                >
                                    Lost Ticket
                                </Button>
                            </Box>

                        )}
                        {specFormOpen ? (
                            <Segment basic>
                                {orderType === 'Custom' ? (
                                    <Box>
                                        <Ticket
                                            infoForForm={infoForForm}
                                            orderType={"Custom"}
                                            lineItemPosition={lineItemPosition}
                                            setSpecFormOpen={setSpecFormOpen}
                                            setShowInputs={setShowInputs}
                                            reprintBy={reprintBy}
                                            credential={credential}
                                        />
                                    </Box>
                                ) : null}
                                {orderType === 'Crazy-Custom' ? (
                                    <Box>
                                        <Ticket
                                            infoForForm={infoForForm}
                                            orderType={"CrazyCustom"}
                                            lineItemPosition={lineItemPosition}
                                            setSpecFormOpen={setSpecFormOpen}
                                            setShowInputs={setShowInputs}
                                            reprintBy={reprintBy}
                                            credential={credential}
                                        />
                                    </Box>
                                ) : null}
                                {orderType === 'Belt' ? (
                                    <Box>
                                        <Ticket
                                            infoForForm={infoForForm}
                                            orderType={"Belt"}
                                            lineItemPosition={lineItemPosition}
                                            setSpecFormOpen={setSpecFormOpen}
                                            setShowInputs={setShowInputs}
                                            reprintBy={reprintBy}
                                            credential={credential}
                                        />
                                    </Box>
                                ) : null}
                                {orderType === '' || orderType === 'Stock' ? (
                                    <Box>
                                        <Ticket
                                            infoForForm={infoForForm}
                                            orderType={"Stock"}
                                            lineItemPosition={lineItemPosition}
                                            setSpecFormOpen={setSpecFormOpen}
                                            setShowInputs={setShowInputs}
                                            reprintBy={reprintBy}
                                            credential={credential}
                                        />
                                    </Box>
                                ) : null}
                                {orderType === '' || orderType === 'Restock' ? (
                                    <Box>
                                        <Ticket
                                            infoForForm={infoForForm}
                                            orderType={"Restock"}
                                            lineItemPosition={lineItemPosition}
                                            setSpecFormOpen={setSpecFormOpen}
                                            setShowInputs={setShowInputs}
                                            reprintBy={reprintBy}
                                            credential={credential}
                                        />
                                    </Box>
                                ) : null}
                                {orderType=== 'Rebuild' ? (
                                    <Box>
                                        <Ticket
                                            infoForForm={infoForForm}
                                            orderType={"Rebuild"}
                                            lineItemPosition={lineItemPosition}
                                            setSpecFormOpen={setSpecFormOpen}
                                            setShowInputs={setShowInputs}
                                            reprintBy={reprintBy}
                                            credential={credential}
                                        />
                                    </Box>
                                ) : null}
                                {orderType === 'Reheel' ? (
                                    <Box>
                                        <Ticket
                                            infoForForm={infoForForm}
                                            orderType={"Reheel"}
                                            lineItemPosition={lineItemPosition}
                                            setSpecFormOpen={setSpecFormOpen}
                                            setShowInputs={setShowInputs}
                                            reprintBy={reprintBy}
                                            credential={credential}
                                        />
                                    </Box>
                                ) : null}
                                {orderType === 'Resole' ? (
                                    <Box>
                                        <Ticket
                                            infoForForm={infoForForm}
                                            orderType={"Resole"}
                                            lineItemPosition={lineItemPosition}
                                            setSpecFormOpen={setSpecFormOpen}
                                            setShowInputs={setShowInputs}
                                            reprintBy={reprintBy}
                                            credential={credential}
                                        />
                                    </Box>
                                ) : null}
                                {orderType === 'General'? (
                                    <Box>
                                        <Ticket
                                            infoForForm={infoForForm}
                                            orderType={"General"}
                                            lineItemPosition={lineItemPosition}
                                            setSpecFormOpen={setSpecFormOpen}
                                            setShowInputs={setShowInputs}
                                            reprintBy={reprintBy}
                                            credential={credential}
                                        />
                                    </Box>
                                ) : null}
                                {orderType === 'Warranty' ? (
                                    <Box>
                                        <Ticket
                                            infoForForm={infoForForm}
                                            orderType={"Warranty"}
                                            lineItemPosition={lineItemPosition}
                                            setSpecFormOpen={setSpecFormOpen}
                                            setShowInputs={setShowInputs}
                                            reprintBy={reprintBy}
                                            credential={credential}
                                        />
                                    </Box>
                                ) : null}
                                {orderType === 'LostTicket' ? (
                                    <Box>
                                        <Ticket
                                            infoForForm={infoForForm}
                                            orderType={"LostTicket"}
                                            lineItemPosition={lineItemPosition}
                                            setSpecFormOpen={setSpecFormOpen}
                                            setShowInputs={setShowInputs}
                                            reprintBy={reprintBy}
                                            credential={credential}
                                        />
                                    </Box>
                                ) : null}
                                {orderType === 'Tryon' ? (
                                    <Box>
                                        <Ticket
                                            infoForForm={infoForForm}
                                            orderType={"Tryon"}
                                            lineItemPosition={lineItemPosition}
                                            setSpecFormOpen={setSpecFormOpen}
                                            setShowInputs={setShowInputs}
                                            reprintBy={reprintBy}
                                            credential={credential}
                                        />
                                    </Box>
                                ) : null}
                                 {orderType === 'CrazyCustom' ? (
                                    <Box>
                                        <Ticket
                                            infoForForm={infoForForm}
                                            orderType={"CrazyCustom"}
                                            lineItemPosition={lineItemPosition}
                                            setSpecFormOpen={setSpecFormOpen}
                                            setShowInputs={setShowInputs}
                                            reprintBy={reprintBy}
                                            credential={credential}
                                        />
                                    </Box>
                                ) : null}
                            </Segment>
                        ) : (
                            <Segment basic>
                                {customReprintButton ? (
                                    <Dimmer inverted active>
                                       <Loader/>
                                    </Dimmer>
                                ) : null}
                            </Segment>
                        )}
                    </Segment>
                </Segment>
            </Modal.Content>
        </Modal>
    );
};
