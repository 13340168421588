import { React, useState } from 'react';
import { Grid, Segment, Input, Button, Header } from 'semantic-ui-react';

export const CustomUppersInput = ({
  height,
  setLeftCustomUppers,
  setRightCustomUppers,
  setQuarters,
  setOpenCustomUppers,
}) => {
  const [leftHeel, setLeftHeel] = useState('');
  const [rightHeel, setRightHeel] = useState('');
  const [leftHeightSix, setLeftSix] = useState('');
  const [leftHeightEight, setLeftEight] = useState('');
  const [leftHeightTen, setLeftTen] = useState('');
  const [leftHeightTwelve, setLeftTwelve] = useState('');
  const [leftHeight14, setLeft14] = useState('');
  const [leftHeight16, setLeft16] = useState('');
  const [leftHeight18, setLeft18] = useState('');
  const [leftHeight20, setLeft20] = useState('');
  const [rightHeightSix, setRightSix] = useState('');
  const [rightHeightEight, setRightEight] = useState('');
  const [rightHeightTen, setRightTen] = useState('');
  const [rightHeightTwelve, setRightTwelve] = useState('');
  const [rightHeight14, setRight14] = useState('');
  const [rightHeight16, setRight16] = useState('');
  const [rightHeight18, setRight18] = useState('');
  const [rightHeight20, setRight20] = useState('');

  const displayLeft = () => {
    if (height === '6"') {
      return (
        <Segment basic>
          <Input
            placeholder='Enter heel'
            value={leftHeel}
            onChange={(_, { value }) => setLeftHeel(value)}
          />
          <Input
            placeholder='Enter 6"'
            value={leftHeightSix}
            onChange={(_, { value }) => setLeftSix(value)}
          />
        </Segment>
      );
    }
    if (height === '8"') {
      return (
        <Segment basic>
          <Input
            placeholder='Enter heel'
            value={leftHeel}
            onChange={(_, { value }) => setLeftHeel(value)}
          />
          <Input
            placeholder='Enter 6"'
            value={leftHeightSix}
            onChange={(_, { value }) => setLeftSix(value)}
          />
          <Input
            placeholder='Enter 8"'
            value={leftHeightEight}
            onChange={(_, { value }) => setLeftEight(value)}
          />
        </Segment>
      );
    }
    if (height === '10"') {
      return (
        <Segment basic>
          <Input
            placeholder='Enter heel'
            value={leftHeel}
            onChange={(_, { value }) => setLeftHeel(value)}
          />
          <Input
            placeholder='Enter 6"'
            value={leftHeightSix}
            onChange={(_, { value }) => setLeftSix(value)}
          />
          <Input
            placeholder='Enter 8"'
            value={leftHeightEight}
            onChange={(_, { value }) => setLeftEight(value)}
          />
          <Input
            placeholder='Enter 10"'
            value={leftHeightTen}
            onChange={(_, { value }) => setLeftTen(value)}
          />
        </Segment>
      );
    }
    if (height === '12"') {
      return (
        <Segment basic>
          <Input
            placeholder='Enter heel'
            value={leftHeel}
            onChange={(_, { value }) => setLeftHeel(value)}
          />
          <Input
            placeholder='Enter 6"'
            value={leftHeightSix}
            onChange={(_, { value }) => setLeftSix(value)}
          />
          <Input
            placeholder='Enter 8"'
            value={leftHeightEight}
            onChange={(_, { value }) => setLeftEight(value)}
          />
          <Input
            placeholder='Enter 10"'
            value={leftHeightTen}
            onChange={(_, { value }) => setLeftTen(value)}
          />
          <Input
            placeholder='Enter 12"'
            value={leftHeightTwelve}
            onChange={(_, { value }) => setLeftTwelve(value)}
          />
        </Segment>
      );
    }
    if (height === '14"') {
      return (
        <Segment basic>
          <Input
            placeholder='Enter heel'
            value={leftHeel}
            onChange={(_, { value }) => setLeftHeel(value)}
          />
          <Input
            placeholder='Enter 6"'
            value={leftHeightSix}
            onChange={(_, { value }) => setLeftSix(value)}
          />
          <Input
            placeholder='Enter 8"'
            value={leftHeightEight}
            onChange={(_, { value }) => setLeftEight(value)}
          />
          <Input
            placeholder='Enter 10"'
            value={leftHeightTen}
            onChange={(_, { value }) => setLeftTen(value)}
          />
          <Input
            placeholder='Enter 12"'
            value={leftHeightTwelve}
            onChange={(_, { value }) => setLeftTwelve(value)}
          />
          <Input
            placeholder='Enter 14"'
            value={leftHeight14}
            onChange={(_, { value }) => setLeft14(value)}
          />
        </Segment>
      );
    }
    if (height === '16"') {
      return (
        <Segment basic>
          <Input
            placeholder='Enter heel'
            value={leftHeel}
            onChange={(_, { value }) => setLeftHeel(value)}
          />
          <Input
            placeholder='Enter 6"'
            value={leftHeightSix}
            onChange={(_, { value }) => setLeftSix(value)}
          />
          <Input
            placeholder='Enter 8"'
            value={leftHeightEight}
            onChange={(_, { value }) => setLeftEight(value)}
          />
          <Input
            placeholder='Enter 10"'
            value={leftHeightTen}
            onChange={(_, { value }) => setLeftTen(value)}
          />
          <Input
            placeholder='Enter 12"'
            value={leftHeightTwelve}
            onChange={(_, { value }) => setLeftTwelve(value)}
          />
          <Input
            placeholder='Enter 14"'
            value={leftHeight14}
            onChange={(_, { value }) => setLeft14(value)}
          />
          <Input
            placeholder='Enter 16"'
            value={leftHeight16}
            onChange={(_, { value }) => setLeft16(value)}
          />
        </Segment>
      );
    }
    if (height === '18"') {
      return (
        <Segment basic>
          <Input
            placeholder='Enter heel'
            value={leftHeel}
            onChange={(_, { value }) => setLeftHeel(value)}
          />
          <Input
            placeholder='Enter 6"'
            value={leftHeightSix}
            onChange={(_, { value }) => setLeftSix(value)}
          />
          <Input
            placeholder='Enter 8"'
            value={leftHeightEight}
            onChange={(_, { value }) => setLeftEight(value)}
          />
          <Input
            placeholder='Enter 10"'
            value={leftHeightTen}
            onChange={(_, { value }) => setLeftTen(value)}
          />
          <Input
            placeholder='Enter 12"'
            value={leftHeightTwelve}
            onChange={(_, { value }) => setLeftTwelve(value)}
          />
          <Input
            placeholder='Enter 14"'
            value={leftHeight14}
            onChange={(_, { value }) => setLeft14(value)}
          />
          <Input
            placeholder='Enter 16"'
            value={leftHeight16}
            onChange={(_, { value }) => setLeft16(value)}
          />
          <Input
            placeholder='Enter 18"'
            value={leftHeight18}
            onChange={(_, { value }) => setLeft18(value)}
          />
        </Segment>
      );
    }
    if (height === '20"') {
      return (
        <Segment basic>
          <Input
            placeholder='Enter heel'
            value={leftHeel}
            onChange={(_, { value }) => setLeftHeel(value)}
          />
          <Input
            placeholder='Enter 6"'
            value={leftHeightSix}
            onChange={(_, { value }) => setLeftSix(value)}
          />
          <Input
            placeholder='Enter 8"'
            value={leftHeightEight}
            onChange={(_, { value }) => setLeftEight(value)}
          />
          <Input
            placeholder='Enter 10"'
            value={leftHeightTen}
            onChange={(_, { value }) => setLeftTen(value)}
          />
          <Input
            placeholder='Enter 12"'
            value={leftHeightTwelve}
            onChange={(_, { value }) => setLeftTwelve(value)}
          />
          <Input
            placeholder='Enter 14"'
            value={leftHeight14}
            onChange={(_, { value }) => setLeft14(value)}
          />
          <Input
            placeholder='Enter 16"'
            value={leftHeight16}
            onChange={(_, { value }) => setLeft16(value)}
          />
          <Input
            placeholder='Enter 18"'
            value={leftHeight18}
            onChange={(_, { value }) => setLeft18(value)}
          />
          <Input
            placeholder='Enter 20"'
            value={leftHeight20}
            onChange={(_, { value }) => setLeft20(value)}
          />

        </Segment>
      );
    }
  };

  const displayRight = () => {
    if (height === '6"') {
      return (
        <Segment basic>
          <Input
            placeholder='Enter heel'
            value={rightHeel}
            onChange={(_, { value }) => setRightHeel(value)}
          />
          <Input
            placeholder='Enter 6"'
            value={rightHeightSix}
            onChange={(_, { value }) => setRightSix(value)}
          />
        </Segment>
      );
    }
    if (height === '8"') {
      return (
        <Segment basic>
          <Input
            placeholder='Enter heel'
            value={rightHeel}
            onChange={(_, { value }) => setRightHeel(value)}
          />
          <Input
            placeholder='Enter 6"'
            value={rightHeightSix}
            onChange={(_, { value }) => setRightSix(value)}
          />
          <Input
            placeholder='Enter 8"'
            value={rightHeightEight}
            onChange={(_, { value }) => setRightEight(value)}
          />
        </Segment>
      );
    }
    if (height === '10"') {
      return (
        <Segment basic>
          <Input
            placeholder='Enter heel'
            value={rightHeel}
            onChange={(_, { value }) => setRightHeel(value)}
          />
          <Input
            placeholder='Enter 6"'
            value={rightHeightSix}
            onChange={(_, { value }) => setRightSix(value)}
          />
          <Input
            placeholder='Enter 8"'
            value={rightHeightEight}
            onChange={(_, { value }) => setRightEight(value)}
          />
          <Input
            placeholder='Enter 10"'
            value={rightHeightTen}
            onChange={(_, { value }) => setRightTen(value)}
          />
        </Segment>
      );
    }
    if (height === '12"') {
      return (
        <Segment basic>
          <Input
            placeholder='Enter heel'
            value={rightHeel}
            onChange={(_, { value }) => setRightHeel(value)}
          />
          <Input
            placeholder='Enter 6"'
            value={rightHeightSix}
            onChange={(_, { value }) => setRightSix(value)}
          />
          <Input
            placeholder='Enter 8"'
            value={rightHeightEight}
            onChange={(_, { value }) => setRightEight(value)}
          />
          <Input
            placeholder='Enter 10"'
            value={rightHeightTen}
            onChange={(_, { value }) => setRightTen(value)}
          />
          <Input
            placeholder='Enter 12"'
            value={rightHeightTwelve}
            onChange={(_, { value }) => setRightTwelve(value)}
          />
        </Segment>
      );
    }
    if (height === '14"') {
      return (
        <Segment basic>
          <Input
            placeholder='Enter heel'
            value={rightHeel}
            onChange={(_, { value }) => setRightHeel(value)}
          />
          <Input
            placeholder='Enter 6"'
            value={rightHeightSix}
            onChange={(_, { value }) => setRightSix(value)}
          />
          <Input
            placeholder='Enter 8"'
            value={rightHeightEight}
            onChange={(_, { value }) => setRightEight(value)}
          />
          <Input
            placeholder='Enter 10"'
            value={rightHeightTen}
            onChange={(_, { value }) => setRightTen(value)}
          />
          <Input
            placeholder='Enter 12"'
            value={rightHeightTwelve}
            onChange={(_, { value }) => setRightTwelve(value)}
          />
          <Input
            placeholder='Enter 14"'
            value={rightHeight14}
            onChange={(_, { value }) => setRight14(value)}
          />
        </Segment>
      );
    }
    if (height === '16"') {
      return (
        <Segment basic>
          <Input
            placeholder='Enter heel'
            value={rightHeel}
            onChange={(_, { value }) => setRightHeel(value)}
          />
          <Input
            placeholder='Enter 6"'
            value={rightHeightSix}
            onChange={(_, { value }) => setRightSix(value)}
          />
          <Input
            placeholder='Enter 8"'
            value={rightHeightEight}
            onChange={(_, { value }) => setRightEight(value)}
          />
          <Input
            placeholder='Enter 10"'
            value={rightHeightTen}
            onChange={(_, { value }) => setRightTen(value)}
          />
          <Input
            placeholder='Enter 12"'
            value={rightHeightTwelve}
            onChange={(_, { value }) => setRightTwelve(value)}
          />
          <Input
            placeholder='Enter 14"'
            value={rightHeight14}
            onChange={(_, { value }) => setRight14(value)}
          />
          <Input
            placeholder='Enter 16"'
            value={rightHeight16}
            onChange={(_, { value }) => setRight16(value)}
          />
        </Segment>
      );
    }
    if (height === '18"') {
      return (
        <Segment basic>
          <Input
            placeholder='Enter heel'
            value={rightHeel}
            onChange={(_, { value }) => setRightHeel(value)}
          />
          <Input
            placeholder='Enter 6"'
            value={rightHeightSix}
            onChange={(_, { value }) => setRightSix(value)}
          />
          <Input
            placeholder='Enter 8"'
            value={rightHeightEight}
            onChange={(_, { value }) => setRightEight(value)}
          />
          <Input
            placeholder='Enter 10"'
            value={rightHeightTen}
            onChange={(_, { value }) => setRightTen(value)}
          />
          <Input
            placeholder='Enter 12"'
            value={rightHeightTwelve}
            onChange={(_, { value }) => setRightTwelve(value)}
          />
          <Input
            placeholder='Enter 14"'
            value={rightHeight14}
            onChange={(_, { value }) => setRight14(value)}
          />
          <Input
            placeholder='Enter 16"'
            value={rightHeight16}
            onChange={(_, { value }) => setRight16(value)}
          />
          <Input
            placeholder='Enter 18"'
            value={rightHeight20}
            onChange={(_, { value }) => setRight18(value)}
          />
        </Segment>
      );
    }
    if (height === '20"') {
      return (
        <Segment basic>
          <Input
            placeholder='Enter heel'
            value={rightHeel}
            onChange={(_, { value }) => setRightHeel(value)}
          />
          <Input
            placeholder='Enter 6"'
            value={rightHeightSix}
            onChange={(_, { value }) => setRightSix(value)}
          />
          <Input
            placeholder='Enter 8"'
            value={rightHeightEight}
            onChange={(_, { value }) => setRightEight(value)}
          />
          <Input
            placeholder='Enter 10"'
            value={rightHeightTen}
            onChange={(_, { value }) => setRightTen(value)}
          />
          <Input
            placeholder='Enter 12"'
            value={rightHeightTwelve}
            onChange={(_, { value }) => setRightTwelve(value)}
          />
          <Input
            placeholder='Enter 14"'
            value={rightHeight14}
            onChange={(_, { value }) => setRight14(value)}
          />
          <Input
            placeholder='Enter 16"'
            value={rightHeight16}
            onChange={(_, { value }) => setRight16(value)}
          />
          <Input
            placeholder='Enter 18"'
            value={rightHeight18}
            onChange={(_, { value }) => setRight18(value)}
          />
          <Input
            placeholder='Enter 20"'
            value={rightHeight18}
            onChange={(_, { value }) => setRight18(value)}
          />
        </Segment>
      );
    }
  };

  const sendToDisplay = () => {
    //Right
    if (height === '6"') {
      setRightCustomUppers('R: H: ' + rightHeel + ', 6: ' + rightHeightSix);
    }
    if (height === '8"') {
      setRightCustomUppers(
        'R: H: ' +
          rightHeel +
          '", 6: ' +
          rightHeightSix +
          '", 8: ' +
          rightHeightEight +
          '"'
      );
    }
    if (height === '10"') {
      setRightCustomUppers(
        'R: H: ' +
          rightHeel +
          '", 6: ' +
          rightHeightSix +
          '", 8: ' +
          rightHeightEight +
          '", 10: ' +
          rightHeightTen +
          '"'
      );
    }
    if (height === '12"') {
      setRightCustomUppers(
        'H: ' +
          rightHeel +
          '", 6: ' +
          rightHeightSix +
          '", 8: ' +
          rightHeightEight +
          '", 10: ' +
          rightHeightTen +
          '", 12: ' +
          rightHeightTwelve +
          '"'
      );
    }
    if (height === '14"') {
      setRightCustomUppers(
        'R: H: ' +
          rightHeel +
          '", 6: ' +
          rightHeightSix +
          '", 8: ' +
          rightHeightEight +
          '", 10: ' +
          rightHeightTen +
          '", 12: ' +
          rightHeightTwelve +
          '", 14: ' +
          rightHeight14 +
          '"'
      );
    }
    if (height === '16"') {
      setRightCustomUppers(
        'R: H: ' +
          rightHeel +
          '", 6: ' +
          rightHeightSix +
          '", 8: ' +
          rightHeightEight +
          '", 10: ' +
          rightHeightTen +
          '", 12: ' +
          rightHeightTwelve +
          '", 14: ' +
          rightHeight14 +
          '", 16: ' +
          rightHeight16 +
          '"'
      );
    }
    if (height === '18"') {
      setRightCustomUppers(
        'R: H: ' +
          rightHeel +
          '", 6: ' +
          rightHeightSix +
          '", 8: ' +
          rightHeightEight +
          '", 10: ' +
          rightHeightTen +
          '", 12: ' +
          rightHeightTwelve +
          '", 14: ' +
          rightHeight14 +
          '", 16: ' +
          rightHeight16 +
          '", 18: ' +
          rightHeight18 +
          '"'
      );
    }
    if (height === '20"') {
      setRightCustomUppers(
        'R: H: ' +
          rightHeel +
          '", 6: ' +
          rightHeightSix +
          '", 8: ' +
          rightHeightEight +
          '", 10: ' +
          rightHeightTen +
          '", 12: ' +
          rightHeightTwelve +
          '", 14: ' +
          rightHeight14 +
          '", 16: ' +
          rightHeight16 +
          '", 18: ' +
          rightHeight18 +
          '", 20: ' +
          rightHeight18 +
          '"'
      );
    }
    //Left
    if (height === '6"') {
      setLeftCustomUppers('L: H: ' + leftHeel + ', 6: ' + leftHeightSix);
    }
    if (height === '8"') {
      setLeftCustomUppers(
        'L: H: ' +
          leftHeel +
          '", 6: ' +
          leftHeightSix +
          '", 8: ' +
          leftHeightEight +
          '"'
      );
    }
    if (height === '10"') {
      setLeftCustomUppers(
        'L: H: ' +
          leftHeel +
          '", 6: ' +
          leftHeightSix +
          '", 8: ' +
          leftHeightEight +
          '", 10: ' +
          leftHeightTen +
          '"'
      );
    }
    if (height === '12"') {
      setLeftCustomUppers(
        'H: ' +
          leftHeel +
          '", 6: ' +
          leftHeightSix +
          '", 8: ' +
          leftHeightEight +
          '", 10: ' +
          leftHeightTen +
          '", 12: ' +
          leftHeightTwelve +
          '"'
      );
    }
    if (height === '14"') {
      setLeftCustomUppers(
        'L: H: ' +
          leftHeel +
          '", 6: ' +
          leftHeightSix +
          '", 8: ' +
          leftHeightEight +
          '", 10: ' +
          leftHeightTen +
          '", 12: ' +
          leftHeightTwelve +
          '", 14: ' +
          leftHeight14 +
          '"'
      );
    }
    if (height === '16"') {
      setLeftCustomUppers(
        'L: H: ' +
          leftHeel +
          '", 6: ' +
          leftHeightSix +
          '", 8: ' +
          leftHeightEight +
          '", 10: ' +
          leftHeightTen +
          '", 12: ' +
          leftHeightTwelve +
          '", 14: ' +
          leftHeight14 +
          '", 16: ' +
          leftHeight16 +
          '"'
      );
    }
    if (height === '18"') {
      setLeftCustomUppers(
        'L: H: ' +
          leftHeel +
          '", 6: ' +
          leftHeightSix +
          '", 8: ' +
          leftHeightEight +
          '", 10: ' +
          leftHeightTen +
          '", 12: ' +
          leftHeightTwelve +
          '", 14: ' +
          leftHeight14 +
          '", 16: ' +
          leftHeight16 +
          '", 18: ' +
          leftHeight18 +
          '"'
      );
    }
    if (height === '20"') {
      setLeftCustomUppers(
        'L: H: ' +
          leftHeel +
          '", 6: ' +
          leftHeightSix +
          '", 8: ' +
          leftHeightEight +
          '", 10: ' +
          leftHeightTen +
          '", 12: ' +
          leftHeightTwelve +
          '", 14: ' +
          leftHeight14 +
          '", 16: ' +
          leftHeight16 +
          '", 18: ' +
          leftHeight18 +
          '", 20: ' +
          leftHeight20 +
          '"'
      );
    }
    setQuarters('');
    setOpenCustomUppers(false);
  };

  return (
    <Segment>
      <Grid celled>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header>Left Custom Uppers</Header>
            {displayLeft()}
          </Grid.Column>
          <Grid.Column width={8}>
            <Header>Right Custom Uppers</Header>
            {displayRight()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Button type='button' color='green' onClick={() => sendToDisplay()}>
        Update
      </Button>
      <Button
        type='button'
        color='red'
        onClick={() => setOpenCustomUppers(false)}
      >
        Close
      </Button>
    </Segment>
  );
};
