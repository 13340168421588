import {React, useState} from 'react';
import {Modal, Button, Header, Card, Segment} from 'semantic-ui-react';
import {Box} from 'theme-ui';
import {NewTicket} from './new-ticket'

export const PrintTicketModal = ({
                                     customerName,
                                     orderNumber,
                                     orderId,
                                     totalLineItems,
                                     orderedDate,
                                     reprintBy,
                                     credential,
                                 }) => {
    const [open, setOpen] = useState(false);
    const [displayEditor, setDisplayEditor] = useState(false);
    const [selectedItem, setSelectedItem] = useState('');
    
    const openCustomBox = () => {
        setDisplayEditor(true);
        setSelectedItem('Custom');
    };

    const openStockedBox = () => {
        setDisplayEditor(true);
        setSelectedItem('Stocked');
    };

    const openRestockedBox = () => {
        setDisplayEditor(true);
        setSelectedItem('Stocked');
    };

    const openBeltBox = () => {
        setDisplayEditor(true);
        setSelectedItem('Belt');
    };

    const openRebuildBox = () => {
        setDisplayEditor(true);
        setSelectedItem('Rebuild');
    };

    const openResoleBox = () => {
        setDisplayEditor(true);
        setSelectedItem('Resole');
    };

    const openReheelBox = () => {
        setDisplayEditor(true);
        setSelectedItem('Reheel');
    };

    const openGeneralRepairBox = () => {
        setDisplayEditor(true);
        setSelectedItem('GeneralRepair');
    };

    const openWarrantyBox = () => {
        setDisplayEditor(true);
        setSelectedItem('Warranty');
    };

    const openTryonBox = () => {
        setDisplayEditor(true);
        setSelectedItem('Tryon');
    };

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            trigger={
                <Box>
                    <Button color='green'>Print New Ticket</Button>
                </Box>
            }
        >
            <Modal.Header>Print New Ticket for Order #{orderId}</Modal.Header>
            <Modal.Content>
                {!displayEditor ? (
                    <Box>
                        <Header as='h2' textAlign='center'>
                            Select which ticket you'd like to create manually
                        </Header>
                        <Card.Group itemsPerRow={2} stackable>
                            <Card onClick={() => openCustomBox()}>
                                <Card.Content>
                                    <Card.Header>Custom Boot Ticket</Card.Header>
                                    <Card.Meta>
                                        <span>Ticket for a custom boot</span>
                                    </Card.Meta>
                                </Card.Content>
                            </Card>
                            <Card onClick={() => openStockedBox()}>
                                <Card.Content>
                                    <Card.Header>Stock Boot Ticket</Card.Header>
                                    <Card.Meta>
                                        <span>Ticket for a stock boot</span>
                                    </Card.Meta>
                                </Card.Content>
                            </Card>
                            <Card onClick={() => openRestockedBox()}>
                                <Card.Content>
                                    <Card.Header>Restock Boot Ticket</Card.Header>
                                    <Card.Meta>
                                        <span>Ticket for a restock boot</span>
                                    </Card.Meta>
                                </Card.Content>
                            </Card>
                            <Card onClick={() => openBeltBox()}>
                                <Card.Content>
                                    <Card.Header>Belt Ticket</Card.Header>
                                    <Card.Meta>
                                        <span>Ticket for a belt</span>
                                    </Card.Meta>
                                </Card.Content>
                            </Card>
                            <Card onClick={() => openRebuildBox()}>
                                <Card.Content>
                                    <Card.Header>Rebuild Ticket</Card.Header>
                                    <Card.Meta>
                                        <span>Ticket for a rebuild</span>
                                    </Card.Meta>
                                </Card.Content>
                            </Card>
                            <Card onClick={() => openResoleBox()}>
                                <Card.Content>
                                    <Card.Header>Resole Ticket</Card.Header>
                                    <Card.Meta>
                                        <span>Ticket for a resole</span>
                                    </Card.Meta>
                                </Card.Content>
                            </Card>
                            <Card onClick={() => openReheelBox()}>
                                <Card.Content>
                                    <Card.Header>Reheel Ticket</Card.Header>
                                    <Card.Meta>
                                        <span>Ticket for a reheel</span>
                                    </Card.Meta>
                                </Card.Content>
                            </Card>
                            <Card onClick={() => openGeneralRepairBox()}>
                                <Card.Content>
                                    <Card.Header>General Repair Ticket</Card.Header>
                                    <Card.Meta>
                                        <span>Ticket for a general repair</span>
                                    </Card.Meta>
                                </Card.Content>
                            </Card>
                            <Card onClick={() => openWarrantyBox()}>
                                <Card.Content>
                                    <Card.Header>Warranty Ticket</Card.Header>
                                    <Card.Meta>
                                        <span>Ticket for a warranty</span>
                                    </Card.Meta>
                                </Card.Content>
                            </Card>
                            <Card onClick={() => openTryonBox()}>
                                <Card.Content>
                                    <Card.Header>Try on Ticket</Card.Header>
                                    <Card.Meta>
                                        <span>Ticket for a try ons</span>
                                    </Card.Meta>
                                </Card.Content>
                            </Card>
                        </Card.Group>
                    </Box>
                ) : null}
                {selectedItem === 'Custom' && displayEditor ? (
                    <Box>
                        <Header as='h2' textAlign='center'>
                            Custom Boot Ticket
                        </Header>
                        <NewTicket
                            orderType={"Custom"}
                            totalLineItems={totalLineItems}
                            customerName={customerName}
                            setDisplayEditor={setDisplayEditor}
                            orderNumber={orderNumber}
                            orderId={orderId}
                            orderedDate={orderedDate}
                            reprintBy={reprintBy}
                            credential={credential}
                        />
                    </Box>
                ) : null}
                {(selectedItem === 'Stocked' || selectedItem === 'Not-Stocked') &&
                displayEditor ? (
                    <Box>
                        <Header as='h2' textAlign='center'>
                            Stock Boot Ticket
                        </Header>
                        <NewTicket
                            orderType={"Stock"}
                            totalLineItems={totalLineItems}
                            customerName={customerName}
                            setDisplayEditor={setDisplayEditor}
                            orderNumber={orderNumber}
                            orderId={orderId}
                            orderedDate={orderedDate}
                            reprintBy={reprintBy}
                            credential={credential}
                        />
                    </Box>
                ) : null}
                {selectedItem === 'Belt' && displayEditor ? (
                    <Box>
                        <Header as='h2' textAlign='center'>
                            Belt Boot Ticket
                        </Header>
                        <NewTicket
                            orderType={"Belt"}
                            totalLineItems={totalLineItems}
                            customerName={customerName}
                            setDisplayEditor={setDisplayEditor}
                            orderNumber={orderNumber}
                            orderId={orderId}
                            orderedDate={orderedDate}
                            reprintBy={reprintBy}
                            credential={credential}
                        />
                    </Box>
                ) : null}
                {selectedItem === 'Rebuild' && displayEditor ? (
                    <Box>
                        <Header as='h2' textAlign='center'>
                            Rebuild Boot Ticket
                        </Header>
                        <NewTicket
                            orderType={"Rebuild"}
                            totalLineItems={totalLineItems}
                            customerName={customerName}
                            setDisplayEditor={setDisplayEditor}
                            orderNumber={orderNumber}
                            orderId={orderId}
                            orderedDate={orderedDate}
                            reprintBy={reprintBy}
                            credential={credential}
                        />
                    </Box>
                ) : null}
                {selectedItem === 'Resole' && displayEditor ? (
                    <Box>
                        <Header as='h2' textAlign='center'>
                            Resole Boot Ticket
                        </Header>
                        <NewTicket
                            orderType={"Resole"}
                            totalLineItems={totalLineItems}
                            customerName={customerName}
                            setDisplayEditor={setDisplayEditor}
                            orderNumber={orderNumber}
                            orderId={orderId}
                            orderedDate={orderedDate}
                            reprintBy={reprintBy}
                            credential={credential}
                        />
                    </Box>
                ) : null}
                 {selectedItem === 'Reheel' && displayEditor ? (
                    <Box>
                        <Header as='h2' textAlign='center'>
                            Reheel Boot Ticket
                        </Header>
                        <NewTicket
                            orderType={"Reheel"}
                            totalLineItems={totalLineItems}
                            customerName={customerName}
                            setDisplayEditor={setDisplayEditor}
                            orderNumber={orderNumber}
                            orderId={orderId}
                            orderedDate={orderedDate}
                            reprintBy={reprintBy}
                            credential={credential}
                        />
                    </Box>
                ) : null}
                {selectedItem === 'GeneralRepair' && displayEditor ? (
                    <Box>
                        <Header as='h2' textAlign='center'>
                            GeneralRepair Boot Ticket
                        </Header>
                        <NewTicket
                            orderType={"General"}
                            totalLineItems={totalLineItems}
                            customerName={customerName}
                            setDisplayEditor={setDisplayEditor}
                            orderNumber={orderNumber}
                            orderId={orderId}
                            orderedDate={orderedDate}
                            reprintBy={reprintBy}
                            credential={credential}
                        />
                    </Box>
                ) : null}
                 {selectedItem === 'Warranty' && displayEditor ? (
                    <Box>
                        <Header as='h2' textAlign='center'>
                            Warranty Ticket
                        </Header>
                        <NewTicket
                            orderType={"Warranty"}
                            totalLineItems={totalLineItems}
                            customerName={customerName}
                            setDisplayEditor={setDisplayEditor}
                            orderNumber={orderNumber}
                            orderId={orderId}
                            orderedDate={orderedDate}
                            reprintBy={reprintBy}
                            credential={credential}
                        />
                    </Box>
                ) : null}
                {selectedItem === 'Tryon' && displayEditor ? (
                    <Box>
                        <Header as='h2' textAlign='center'>
                            Try On Ticket
                        </Header>
                        <NewTicket
                            orderType={"Tryon"}
                            totalLineItems={totalLineItems}
                            customerName={customerName}
                            setDisplayEditor={setDisplayEditor}
                            orderNumber={orderNumber}
                            orderId={orderId}
                            orderedDate={orderedDate}
                            reprintBy={reprintBy}
                            credential={credential}
                        />
                    </Box>
                ) : null}
            </Modal.Content>
        </Modal>
    );
};
