import { React, useEffect, useState } from 'react';
import {
  Grid,
  Segment,
  Input,
  Button,
  Header,
  Dropdown,
  Form,
} from 'semantic-ui-react';
import { Box } from 'theme-ui';

export const SizingCustom = ({
  size,
  setSize,
  setSizeLeft,
  setSizeRight,
  setOpenSizeBox,
  diffSizeLeftLength,
  diffSizeLeftWidth,
  diffSizeRightLength,
  diffSizeRightWidth,
  setDiffSizeLeftLength,
  setDiffSizeRightLength,
  setDiffSizeLeftWidth,
  setDiffSizeRightWidth,
}) => {
  const [openSameSizeBootsBox, setOpenSameSizeBootsBox] = useState(false);
  const [openDiffSizeBootsBox, setOpenDiffSizeBootsBox] = useState(false);
  const [sameSizeLength, setSameSizeLength] = useState('');
  const [sameSizeWidth, setSameSizeWidth] = useState('');

  const footWidthOptions = [
    { key: 'A', text: 'A', value: 'A' },
    { key: 'B', text: 'B', value: 'B' },
    { key: 'C', text: 'C', value: 'C' },
    { key: 'D', text: 'D', value: 'D' },
    { key: 'E', text: 'E', value: 'E' },
    { key: '2E', text: '2E', value: '2E' },
    { key: '3E', text: '3E', value: '3E' },
    { key: '4E', text: '4E', value: '4E' },
    { key: '5E', text: '5E', value: '5E' },
    { key: '6E', text: '6E', value: '6E' },
    { key: '7E', text: '7E', value: '7E' },
    { key: '8E', text: '8E', value: '8E' },
  ];

  const setSameSizeOpen = () => {
    setOpenSameSizeBootsBox(true);
    setOpenDiffSizeBootsBox(false);
  };

  const setDiffSizeOpen = () => {
    setOpenDiffSizeBootsBox(true);
    setOpenSameSizeBootsBox(false);
  };

  const submitSameSizeUpdate = () => {
    setSize('Length: ' + sameSizeLength + ' | Width: ' + sameSizeWidth);
    setSizeLeft('');
    setSizeRight('');
    setOpenSizeBox(false);
  };

  const submitDiffSizeUpdate = () => {
    setSizeLeft(
      'L: Length: ' + diffSizeLeftLength + ' | Width: ' + diffSizeLeftWidth
    );
    setSizeRight(
      'R: Length: ' + diffSizeRightLength + ' | Width: ' + diffSizeRightWidth
    );
    setSize('');
    setOpenSizeBox(false);
  };

  useEffect(() => {
    if (size !== '') {
      setSameSizeOpen();
    } else {
      setDiffSizeOpen();
    }
  }, []);
  return (
    <Segment>
      <Button
        type='button'
        onClick={() => {
          setSameSizeOpen();
        }}
      >
        Same Size Boots
      </Button>
      <Button
        type='button'
        onClick={() => {
          setDiffSizeOpen();
        }}
      >
        Different Size Boots
      </Button>
      {openSameSizeBootsBox ? (
        <Segment basic>
          <Header as='h1'>Same Size Boots</Header>
          <Header as='h3'>Foot Length</Header>
          <Dropdown
            placeholder='Select the size'
            fluid
            selection
            value={sameSizeLength}
            onChange={(_, { value }) => setSameSizeLength(value)}
            options={[
              { key: '3', text: '3', value: '3' },
              { key: '3.5', text: '3.5', value: '3.5' },
              { key: '4', text: '4', value: '4' },
              { key: '4.5', text: '4.5', value: '4.5' },
              { key: '5', text: '5', value: '5' },
              { key: '5.5', text: '5.5', value: '5.5' },
              { key: '6', text: '6', value: '6' },
              { key: '6.5', text: '6.5', value: '6.5' },
              { key: '7', text: '7', value: '7' },
              { key: '7.5', text: '7.5', value: '7.5' },
              { key: '8', text: '8', value: '8' },
              { key: '8.5', text: '8.5', value: '8.5' },
              { key: '9', text: '9', value: '9' },
              { key: '9.5', text: '9.5', value: '9.5' },
              { key: '10', text: '10', value: '10' },
              { key: '10.5', text: '10.5', value: '10.5' },
              { key: '11', text: '11', value: '11' },
              { key: '11.5', text: '11.5', value: '11.5' },
              { key: '12', text: '12', value: '12' },
              { key: '12.5', text: '12.5', value: '12.5' },
              { key: '13', text: '13', value: '13' },
              { key: '13.5', text: '13.5', value: '13.5' },
              { key: '14', text: '14', value: '14' },
              { key: '14.5', text: '14.5', value: '14.5' },
              { key: '15', text: '15', value: '15' },
              { key: 'Set length', text: 'Set length', value: '' },
            ]}
            />
          <Header as='h3'>Foot Width</Header>
          <Dropdown
            placeholder='Select the width'
            fluid
            selection
            value={sameSizeWidth}
            onChange={(_, { value }) => setSameSizeWidth(value)}
            options={footWidthOptions}
          />
          <Segment basic>
            <Button
              type='button'
              color='green'
              onClick={() => submitSameSizeUpdate()}
            >
              Update
            </Button>
            <Button
              type='button'
              color='red'
              onClick={() => setOpenSizeBox(false)}
            >
              Close
            </Button>
          </Segment>
        </Segment>
      ) : (
        <Box>
          <Grid celled>
            <Grid.Row>
              <Grid.Column width={8}>
                <Header as='h1'>Left Sizing</Header>
                <Header as='h3'>Left Foot Length</Header>
                <Dropdown
                      placeholder='Select the left foot length'
                      fluid
                      selection
                      value={diffSizeLeftLength}
                      onChange={(_, { value }) => setDiffSizeLeftLength(value)}
                      options={[
                        { key: '3', text: '3', value: '3' },
                        { key: '3.5', text: '3.5', value: '3.5' },
                        { key: '4', text: '4', value: '4' },
                        { key: '4.5', text: '4.5', value: '4.5' },
                        { key: '5', text: '5', value: '5' },
                        { key: '5.5', text: '5.5', value: '5.5' },
                        { key: '6', text: '6', value: '6' },
                        { key: '6.5', text: '6.5', value: '6.5' },
                        { key: '7', text: '7', value: '7' },
                        { key: '7.5', text: '7.5', value: '7.5' },
                        { key: '8', text: '8', value: '8' },
                        { key: '8.5', text: '8.5', value: '8.5' },
                        { key: '9', text: '9', value: '9' },
                        { key: '9.5', text: '9.5', value: '9.5' },
                        { key: '10', text: '10', value: '10' },
                        { key: '10.5', text: '10.5', value: '10.5' },
                        { key: '11', text: '11', value: '11' },
                        { key: '11.5', text: '11.5', value: '11.5' },
                        { key: '12', text: '12', value: '12' },
                        { key: '12.5', text: '12.5', value: '12.5' },
                        { key: '13', text: '13', value: '13' },
                        { key: '13.5', text: '13.5', value: '13.5' },
                        { key: '14', text: '14', value: '14' },
                        { key: '14.5', text: '14.5', value: '14.5' },
                        { key: '15', text: '15', value: '15' },
                        { key: 'Set length', text: 'Set length', value: '' },
                 ]}
                />
                <Header as='h3'>Left Foot Width</Header>
                <Dropdown
                  placeholder='Select the width'
                  fluid
                  selection
                  value={diffSizeLeftWidth}
                  color='green'
                  onChange={(_, { value }) => setDiffSizeLeftWidth(value)}
                  options={footWidthOptions}
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <Header as='h1'>Right Sizing</Header>
                <Header as='h3'>Right Foot Length</Header>
                <Dropdown
                      placeholder='Select the right foot length'
                      fluid
                      selection
                      value={diffSizeRightLength}
                      onChange={(_, { value }) => setDiffSizeRightLength(value)}
                      options={[
                        { key: '3', text: '3', value: '3' },
                        { key: '3.5', text: '3.5', value: '3.5' },
                        { key: '4', text: '4', value: '4' },
                        { key: '4.5', text: '4.5', value: '4.5' },
                        { key: '5', text: '5', value: '5' },
                        { key: '5.5', text: '5.5', value: '5.5' },
                        { key: '6', text: '6', value: '6' },
                        { key: '6.5', text: '6.5', value: '6.5' },
                        { key: '7', text: '7', value: '7' },
                        { key: '7.5', text: '7.5', value: '7.5' },
                        { key: '8', text: '8', value: '8' },
                        { key: '8.5', text: '8.5', value: '8.5' },
                        { key: '9', text: '9', value: '9' },
                        { key: '9.5', text: '9.5', value: '9.5' },
                        { key: '10', text: '10', value: '10' },
                        { key: '10.5', text: '10.5', value: '10.5' },
                        { key: '11', text: '11', value: '11' },
                        { key: '11.5', text: '11.5', value: '11.5' },
                        { key: '12', text: '12', value: '12' },
                        { key: '12.5', text: '12.5', value: '12.5' },
                        { key: '13', text: '13', value: '13' },
                        { key: '13.5', text: '13.5', value: '13.5' },
                        { key: '14', text: '14', value: '14' },
                        { key: '14.5', text: '14.5', value: '14.5' },
                        { key: '15', text: '15', value: '15' },
                        { key: 'Set length', text: 'Set length', value: '' },
                 ]}
                />
                <Header as='h3'>Right Foot Width</Header>
                <Dropdown
                  placeholder='Select the width'
                  fluid
                  selection
                  value={diffSizeRightWidth}
                  onChange={(_, { value }) => setDiffSizeRightWidth(value)}
                  options={footWidthOptions}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Segment basic>
            <Button
              type='button'
              color='green'
              onClick={() => submitDiffSizeUpdate()}
            >
              Update
            </Button>
            <Button
              type='button'
              color='red'
              onClick={() => setOpenSizeBox(false)}
            >
              Close
            </Button>
          </Segment>
        </Box>
      )}
    </Segment>
  );
};

export const SizingCrazyCustom = ({
  setSizeLengthLeft,
  setSizeLengthRight,
  setSizeWidthLeft,
  setSizeWidthRight,
  setOpenSizeBox,
}) => {
  const [diffSizeLeftWidth, setDiffSizeLeftWidth] = useState('');
  const [diffSizeRightWidth, setDiffSizeRightWidth] = useState('');
  const [diffSizeLeftLength, setDiffSizeLeftLength] = useState('');
  const [diffSizeRightLength, setDiffSizeRightLength] = useState('');

  const footWidthOptions = [
    { key: 'B', text: 'B', value: 'B' },
    { key: 'C', text: 'C', value: 'C' },
    { key: 'D', text: 'D', value: 'D' },
    { key: 'E', text: 'E', value: 'E' },
    { key: '2E', text: '2E', value: '2E' },
    { key: '3E', text: '3E', value: '3E' },
    { key: '4E', text: '4E', value: '4E' },
    { key: '5E', text: '5E', value: '5E' },
    { key: '6E', text: '6E', value: '6E' },
    { key: '7E', text: '7E', value: '7E' },
    { key: '8E', text: '8E', value: '8E' },
  ];

  const submitDiffSizeUpdate = () => {
    setSizeLengthLeft(diffSizeLeftLength)
    setSizeWidthLeft(diffSizeLeftWidth)
    setSizeLengthRight(diffSizeRightLength)
    setSizeWidthRight(diffSizeRightWidth)
    setOpenSizeBox(false);
  };

  return (
    <Segment>
      <Header as="h1">Sizing</Header>
      <Box>
        <Grid celled>
          <Grid.Row>
            <Grid.Column width={8}>
              <Header as='h1'>Left Sizing</Header>
              <Header as='h3'>Left Foot Length</Header>
              <Dropdown
                      placeholder='Select the left foot length'
                      fluid
                      selection
                      value={diffSizeLeftLength}
                      onChange={(_, { value }) => setDiffSizeLeftLength(value)}
                      options={[
                        { key: '3', text: '3', value: '3' },
                        { key: '3.5', text: '3.5', value: '3.5' },
                        { key: '4', text: '4', value: '4' },
                        { key: '4.5', text: '4.5', value: '4.5' },
                        { key: '5', text: '5', value: '5' },
                        { key: '5.5', text: '5.5', value: '5.5' },
                        { key: '6', text: '6', value: '6' },
                        { key: '6.5', text: '6.5', value: '6.5' },
                        { key: '7', text: '7', value: '7' },
                        { key: '7.5', text: '7.5', value: '7.5' },
                        { key: '8', text: '8', value: '8' },
                        { key: '8.5', text: '8.5', value: '8.5' },
                        { key: '9', text: '9', value: '9' },
                        { key: '9.5', text: '9.5', value: '9.5' },
                        { key: '10', text: '10', value: '10' },
                        { key: '10.5', text: '10.5', value: '10.5' },
                        { key: '11', text: '11', value: '11' },
                        { key: '11.5', text: '11.5', value: '11.5' },
                        { key: '12', text: '12', value: '12' },
                        { key: '12.5', text: '12.5', value: '12.5' },
                        { key: '13', text: '13', value: '13' },
                        { key: '13.5', text: '13.5', value: '13.5' },
                        { key: '14', text: '14', value: '14' },
                        { key: '14.5', text: '14.5', value: '14.5' },
                        { key: '15', text: '15', value: '15' },
                        { key: 'Set length', text: 'Set length', value: '' },
                 ]}
                />
              <Header as='h3'>Left Foot Width</Header>
              <Dropdown
                placeholder='Select the width'
                fluid
                selection
                value={diffSizeLeftWidth}
                color='green'
                onChange={(_, { value }) => setDiffSizeLeftWidth(value)}
                options={footWidthOptions}
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <Header as='h1'>Right Sizing</Header>
              <Header as='h3'>Right Foot Length</Header>
              <Dropdown
                      placeholder='Select the right foot length'
                      fluid
                      selection
                      value={diffSizeRightLength}
                      onChange={(_, { value }) => setDiffSizeRightLength(value)}
                      options={[
                        { key: '3', text: '3', value: '3' },
                        { key: '3.5', text: '3.5', value: '3.5' },
                        { key: '4', text: '4', value: '4' },
                        { key: '4.5', text: '4.5', value: '4.5' },
                        { key: '5', text: '5', value: '5' },
                        { key: '5.5', text: '5.5', value: '5.5' },
                        { key: '6', text: '6', value: '6' },
                        { key: '6.5', text: '6.5', value: '6.5' },
                        { key: '7', text: '7', value: '7' },
                        { key: '7.5', text: '7.5', value: '7.5' },
                        { key: '8', text: '8', value: '8' },
                        { key: '8.5', text: '8.5', value: '8.5' },
                        { key: '9', text: '9', value: '9' },
                        { key: '9.5', text: '9.5', value: '9.5' },
                        { key: '10', text: '10', value: '10' },
                        { key: '10.5', text: '10.5', value: '10.5' },
                        { key: '11', text: '11', value: '11' },
                        { key: '11.5', text: '11.5', value: '11.5' },
                        { key: '12', text: '12', value: '12' },
                        { key: '12.5', text: '12.5', value: '12.5' },
                        { key: '13', text: '13', value: '13' },
                        { key: '13.5', text: '13.5', value: '13.5' },
                        { key: '14', text: '14', value: '14' },
                        { key: '14.5', text: '14.5', value: '14.5' },
                        { key: '15', text: '15', value: '15' },
                        { key: 'Set length', text: 'Set length', value: '' },
                 ]}
                />
              <Header as='h3'>Right Foot Width</Header>
              <Dropdown
                placeholder='Select the width'
                fluid
                selection
                value={diffSizeRightWidth}
                onChange={(_, { value }) => setDiffSizeRightWidth(value)}
                options={footWidthOptions}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Segment basic>
          <Button
            type='button'
            color='green'
            onClick={() => submitDiffSizeUpdate()}
          >
            Update
          </Button>
          <Button
            type='button'
            color='red'
            onClick={() => setOpenSizeBox(false)}
          >
            Close
          </Button>
        </Segment>
      </Box>
      {/* )} */}
    </Segment>
  );
};

export const SizingStock = ({
  setSize,
  sizeWidth,
  sizeLength,
  setSizeLength,
  setSizeWidth,
  setOpenSizeBox,
}) => {
  setSizeLength(sizeLength);
  setSizeWidth(sizeWidth);
  const setSizeText = () => {
    setSize('Length: ' + sizeLength + ' | Width: ' + sizeWidth);
    setOpenSizeBox(false);
  };

  return (
    <Segment>
      <Header as='h1'>Edit Sizing</Header>
      <Header as='h3'>Foot Length</Header>
      <Dropdown
              placeholder='Select the length'
              fluid
              selection
              value={sizeLength}
              onChange={(_, { value }) => setSizeLength(value)}
              options={[
                { key: '3', text: '3', value: '3' },
                { key: '3.5', text: '3.5', value: '3.5' },
                { key: '4', text: '4', value: '4' },
                { key: '4.5', text: '4.5', value: '4.5' },
                { key: '5', text: '5', value: '5' },
                { key: '5.5', text: '5.5', value: '5.5' },
                { key: '6', text: '6', value: '6' },
                { key: '6.5', text: '6.5', value: '6.5' },
                { key: '7', text: '7', value: '7' },
                { key: '7.5', text: '7.5', value: '7.5' },
                { key: '8', text: '8', value: '8' },
                { key: '8.5', text: '8.5', value: '8.5' },
                { key: '9', text: '9', value: '9' },
                { key: '9.5', text: '9.5', value: '9.5' },
                { key: '10', text: '10', value: '10' },
                { key: '10.5', text: '10.5', value: '10.5' },
                { key: '11', text: '11', value: '11' },
                { key: '11.5', text: '11.5', value: '11.5' },
                { key: '12', text: '12', value: '12' },
                { key: '12.5', text: '12.5', value: '12.5' },
                { key: '13', text: '13', value: '13' },
                { key: '13.5', text: '13.5', value: '13.5' },
                { key: '14', text: '14', value: '14' },
                { key: '14.5', text: '14.5', value: '14.5' },
                { key: '15', text: '15', value: '15' },
                { key: 'Set length', text: 'Set length', value: '' },
                 ]}
                />
      <Header as='h3'>Foot Width</Header>
      <Dropdown
        placeholder='Select the width'
        fluid
        selection
        value={sizeWidth}
        onChange={(_, { value }) => setSizeWidth(value)}
        options={[
          { key: 'B', text: 'B', value: 'B' },
          { key: 'C', text: 'C', value: 'C' },
          { key: 'D', text: 'D', value: 'D' },
          { key: 'E', text: 'E', value: 'E' },
          { key: '2E', text: '2E', value: '2E' },
          { key: '3E', text: '3E', value: '3E' },
          { key: '4E', text: '4E', value: '4E' },
          { key: '5E', text: '5E', value: '5E' },
          { key: '6E', text: '6E', value: '6E' },
          { key: '7E', text: '7E', value: '7E' },
          { key: '8E', text: '8E', value: '8E' },
          { key: 'Set width', text: 'Set width', value: '' },
        ]}
      />
      <Box sx={{ mt: 10 }}>
        <Button type='submit' color='orange' onClick={() => setSizeText()}>
          Update
        </Button>
        <Button type='button' color='red' onClick={() => setOpenSizeBox(false)}>
          Close
        </Button>
      </Box>
    </Segment>
  );
};
